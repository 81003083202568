import React from 'react';

import SkewBg from '@common/SkewBg';
import PageHeader from '@common/PageHeader';
import Flex from '@common/Flex';

import Quote from './Quote';
import Avatar from './Avatar';

import { AboutWrapper, AboutInfo } from './About.style';

const About = () => {
  return (
    <AboutWrapper id="about" style={{ marginBottom: 200 }}>
      <PageHeader>
        About Me
        <p id="p">Hey there 👋 I'm Aman Seelay</p>
      </PageHeader>
      <SkewBg />
      <AboutInfo>
        <div>
          <Avatar src="SeelayProfile.webp" />
        </div>
        <p>
          <a className="about__link" href="/#skills">
            <b>Multi-skilled</b>
          </a>{' '}
          Product Designer from India, Passionate about blending{' '}
          <a
            className="about__link"
            target="blank"
            href="//illustrations.seelay.in"
          >
            <b>creativity</b>
          </a>{' '}
          with <b>data</b> to shape intuitive, future-ready products that align
          with both <s> user's</s> <b>people's</b> needs and{' '}
          <b>business goals</b>. Improving the lives of others through design
          and constantly looking to learn new things every day.
          <br></br>
          <br></br>I love to create things by{' '}
          <a className="about__link" href="/#work">
            designing
          </a>{' '}
          ,{' '}
          <a className="about__link" href="/#projects">
            developing
          </a>{' '}
          or{' '}
          <a className="about__link" target="blank" href="//art.seelay.in">
            drawing
          </a>{' '}
          :)
        </p>
      </AboutInfo>

      <Flex justify="space-between" className="quotes__wrapper">
        <Quote>
          <h1>Product Designer</h1>
        </Quote>
        <Quote>
          <h1>Frontend Developer</h1>
        </Quote>
        <Quote>
          <h1>Digital Artist</h1>
        </Quote>
      </Flex>
    </AboutWrapper>
  );
};

export default About;
