import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import svgRect from '@src/static/home_rect.svg';
import seelay from '@src/static/seelay.svg';

import { HeroCard } from './HeroCard';
import { HomeWrapper, Intro } from './Home.style';

import IconLink from '@common/IconLink';
import PageHeader from '@common/PageHeader';
import Flex from '@common/Flex';
import Button from '@common/Button';

import { Card, CardIcon, CardText, CardTitle } from '@common/Card';

const ThingsILove = () => (
  <Flex justify="space-between" align="center">
    <Card>
      <CardIcon>
        <FontAwesomeIcon icon={['fas', 'object-group']} />
      </CardIcon>
      <CardTitle>DESIGN</CardTitle>
      <CardText>
        “I think that design is like a magic wand. If a service or product is
        magic to solve a problem, isn't it the role of design to serve as a
        medium for the user to master it ? We will make every effort to create a
        “magic wand” that matches the user.“
        <br />
        <br />
      </CardText>
    </Card>

    <Card>
      <CardIcon>
        <FontAwesomeIcon icon={['fas', 'terminal']} />
      </CardIcon>
      <CardTitle>TECHNOLOGY</CardTitle>
      <CardText>
        “I’m always curious about new technologies and want to be a person who
        can always keep up with the latest technology and respond to changes. I
        think it is also the mission of designers along with engineers to
        disseminate innovation.“
        <br />
        <br />
      </CardText>
    </Card>

    <Card>
      <CardIcon>
        <FontAwesomeIcon icon={['fas', 'paint-brush']} />
      </CardIcon>
      <CardTitle>ART</CardTitle>
      <CardText>
        “Art comes in different forms and can attract people's attention and
        make things easier to understand and remember. I believe If you study
        all the patterns of Art and make your output more creative, there will
        be a significant return”
        <br />
        <br />
      </CardText>
    </Card>
  </Flex>
);

const Home = () => {
  return (
    <HomeWrapper id="home">
      <img className="svg-rect" src={svgRect} alt=""></img>

      <Intro>
        {/* <Parallax y={[50, -50]} className="home__text"> */}
        <div className="home__text">
          {/* <p>Hi, I’m</p> */}
          <h1>
            AMAN{' '}
            <span className="seelay">
              <img src={seelay} alt="SEELAY" />
            </span>
          </h1>

          <p className="adjust">Lead Designer at TourSoft International</p>

          <div className="home__CTA">
            <Button
              className="cta"
              as="a"
              href="https://drive.google.com/file/d/1HDTCrLS_6MY4G-tFm2Fj8Qc0Yk8JnBPz/view?usp=sharing"
              download
              target="external"
            >
              View Resume
            </Button>

            <div className="home__social">
              <IconLink
                label="Linkedin"
                icon={['fab', 'linkedin']}
                href="//www.linkedin.com/in/imseelay"
              />

              <IconLink
                label="Instagram"
                icon={['fab', 'instagram']}
                href="//instagram.com/imseelay"
              />
              <IconLink
                label="Github"
                icon={['fab', 'github']}
                href="//github.com/ImSeelay"
              />
            </div>
          </div>
        </div>
        {/* </Parallax> */}
        <HeroCard />
      </Intro>

      {/* Things I LOVE */}
      <PageHeader style={{ marginBottom: 30 }}>Things I love ❤️</PageHeader>
      <ThingsILove />
    </HomeWrapper>
  );
};

export default Home;
