import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';
import PageHeader from '@common/PageHeader';
import Slider from 'react-slick';
import { ProjectLinks } from './ProjectTemplate.style';
import { CardText } from '@common/Card';
import Button, { IconButton } from '@common/Button';

const GlobalStyles = createGlobalStyle`
  
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    -webkit-touch-callout: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
  }

  .slick-initialized .slick-slide {
    display: block;
  }


  
/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}







`;

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 3,
  slidesToScroll: 1,
  draggable: true,
  centerMode: true,
  centerPadding: '40px',
  swipeToSlide: true,
  arrows: false,
  accessibility: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        centerPadding: '15px',
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        speed: 500,
        slidesToShow: 1,
        centerPadding: '15px',
      },
    },
  ],
};

const InnerContent = styled.div`
  padding: 190px 0;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`;

const BG = styled.div`
  position: absolute;
  background: ${p =>
    p.theme.dark ? p.theme.secondaryColor : p.theme.gradient};
  transform: skewY(-3deg);
  right: 0;
  left: 0;
  width: 100%;
  min-height: 630px;
  max-height: 740px;
`;

const SmallProjectWrapper = styled.section`
  ${props => props.theme.spacing.sectionBottom};
  margin-top: 100px;
  width: 100%;
  height: 100%;

  .slick-slide {
    padding: 10px;
  }
`;

const SmallProjectCard = styled.div`
  position: relative;
  background-color: ${props => props.theme.bg};
  border-radius: 10px;
  padding: 30px;
  height: 280px;

  h3 {
    margin-bottom: 5px;
  }

  .smallproject__links {
    position: absolute;
    bottom: 20px;
  }
`;

const SmallProjects = () => {
  const smallprojects = useStaticQuery(
    graphql`
      query {
        allSmallprojectsJson {
          edges {
            node {
              id
              title
              links {
                demo
                github
              }
              description
            }
          }
        }
      }
    `
  );

  return (
    <>
      <GlobalStyles />
      <SmallProjectWrapper>
        <PageHeader>
          Open Source
          <p id="p">Building helpful apps for the community 🌍</p>
        </PageHeader>
        <BG />
        <InnerContent>
          <Slider {...settings}>
            {smallprojects.allSmallprojectsJson.edges.map(({ node }) => (
              <SmallProjectCard key={node.id}>
                <h3>{node.title}</h3>
                <CardText>{node.description}</CardText>
                <ProjectLinks className="smallproject__links">
                  <Button target="__blank" as="a" href={node.links.demo}>
                    Live Demo
                  </Button>
                  {node.links.github && (
                    <IconButton
                      label="github"
                      href={node.links.github}
                      icon={['fab', 'github']}
                    />
                  )}
                </ProjectLinks>
              </SmallProjectCard>
            ))}
          </Slider>
        </InnerContent>
      </SmallProjectWrapper>
    </>
  );
};

export default SmallProjects;
