import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { MapInteractionCSS } from 'react-map-interaction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Img from 'gatsby-image';

import PageHeader from '@common/PageHeader';
import Button, { IconButton } from '@common/Button';
import Grid from '@common/Grid';

import {
  DesignsWrapper,
  DesignCard,
  DesignCardFooter,
  Lightbox,
  LightBoxCloseButton,
} from './Designs.style';

const Card = React.memo(({ nodes, currentImg, openLightbox }) => (
  <DesignCard>
    <div
      style={{ width: '100%', height: '100%' }}
      onClick={() => openLightbox(currentImg)}
    >
      <Img
        fluid={currentImg.node.childImageSharp.fluid}
        alt={nodes.node.title}
      />
    </div>

    <DesignCardFooter
      nowrap
      align="center"
      justify="space-between"
      className="ccard__footer"
      onClick={e => e.stopPropagation()}
    >
      <p style={{ fontSize: 16 }}>{nodes.node.title}</p>
      {/* <div>
        <IconButton label="Click On Image To Open" icon={['fas', 'eye']} />
      </div> */}
    </DesignCardFooter>
  </DesignCard>
));

const Designs = () => {
  const [selectedImg, setSelectedImg] = useState(null);
  const [isLightboxOpen, setLightboxOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const handleShowAll = () => {
    setShowAll(true);
  };
  const openLightbox = useCallback(img => {
    setSelectedImg(img);
    setLightboxOpen(true);
  }, []);

  function closeLightBox(e) {
    if (e.target.tagName !== 'IMG') {
      setSelectedImg(null);
      setLightboxOpen(false);
    }
  }

  const designs = useStaticQuery(
    graphql`
      query {
        allDesignsJson(sort: { fields: links___image, order: ASC }) {
          edges {
            node {
              id
              title
              links {
                image
              }
            }
          }
        }
        allFile(
          filter: { name: { regex: "/^design_/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(quality: 90, maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <DesignsWrapper id="branding">
      <PageHeader>
        Branding
        <p id="p">Seelay Trademarks & Brand Elements</p>
      </PageHeader>

      <Grid collapseHeight="1000px" showAll={showAll}>
        {designs.allDesignsJson.edges.map((nodes, index) => {
          let currentImg = designs.allFile.edges[index];
          return (
            <Card
              key={nodes.node.id}
              nodes={nodes}
              currentImg={currentImg}
              openLightbox={openLightbox}
            />
          );
        })}

        {!showAll && (
          <Button onClick={handleShowAll} className="showall__button">
            Show all
          </Button>
        )}
      </Grid>

      {isLightboxOpen && (
        <Lightbox data-testid="lightbox" onClick={closeLightBox}>
          <MapInteractionCSS>
            <Img
              className="lightbox__gatsbyimage"
              fluid={selectedImg.node.childImageSharp.fluid}
            />
          </MapInteractionCSS>

          <LightBoxCloseButton
            tabindex="1"
            onClick={closeLightBox}
            aria-label="Close Lightbox"
          >
            <FontAwesomeIcon icon="times" size="2x" />
          </LightBoxCloseButton>
        </Lightbox>
      )}
    </DesignsWrapper>
  );
};

Card.propTypes = {
  nodes: PropTypes.object.isRequired,
  currentImg: PropTypes.object.isRequired,
  openLightbox: PropTypes.func.isRequired,
};

export default Designs;
